@mixin shadow($color: rgba(0, 0, 0, 0.1), $x: 0, $y: 0, $blur: 10px, $spread: 0) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin flex($direction: row, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin container($width: 100%, $padding: 0, $margin: 0 auto, $rounded: 4px) {
  background-color: rgb(255, 255, 255);
  width: $width;
  margin: $margin;
  padding: $padding;
  border-radius: $rounded;
}

.shadow-1 {
  @include shadow(rgba(0, 0, 0, 0.12), 0, 4px, 8px, 0);
}

.shadow-2 {
  @include shadow(rgba(0, 0, 0, 0.08), 0, 6px, 16px, 0);
}

.shadow-3 {
    @include shadow(rgba(0, 0, 0, 0.06), 0, 2px, 2px, 0);
}

.flex-center {
  @include flex(row, center, center);
}

.flex-column-center {
  @include flex(column, center, center);
}

.items-center {
  align-items: center;
}

.list {
  display: flex;
  flex-direction: var(--direction, column);
  gap: var(--gap, 1rem);

  margin: 0;
  padding: 0;
  list-style: none;
}

// Border Radius Levels
$border-radius-base: 4px;
$border-radius: (
        'sm': $border-radius-base,
        'md': $border-radius-base * 2,
        'lg': $border-radius-base * 3,
        'xl': $border-radius-base * 4,
        'full': 9999px
);

@each $name, $radius in $border-radius {
  .rounded-#{$name} {
    border-radius: $radius;
  }
}


// Border style levels

$border: (
        'none': none,
        'solid': solid,
        'dashed': dashed,
        'dotted': dotted
);


@each $name, $style in $border {
  .border-#{$name} {
    border-style: $style;
  }
}