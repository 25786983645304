// Colors Design System

// 1. Function to Adjust Colors
@function adjust-lightness($color, $amount) {
  @return rgba(red($color), green($color), blue($color), clamp(0, alpha($color) + $amount, 1));
}

@function adjust-darkness($color, $amount) {
  @return rgba(red($color), green($color), blue($color), clamp(0, alpha($color) - $amount, 1));
}

// 2. Primary Color Palette (Using RGBA)
$colors: (
        'primary': rgba(0, 123, 255, 1),
        'primary-dark': adjust-darkness(rgba(0, 123, 255, 1), 0.2),
        'primary-light': adjust-lightness(rgba(0, 123, 255, 1), 0.2),
        'primary-lighter': rgba(230, 246, 255, 1),
        'secondary': rgba(15, 157, 88, 1),
        'secondary-dark': adjust-darkness(rgba(15, 157, 88, 1), 0.2),
        'secondary-light': adjust-lightness(rgba(15, 157, 88, 1), 0.2),
        'accent-dark': adjust-darkness(rgba(231, 76, 60, 1), 0.2),
        'accent-light': adjust-lightness(rgba(231, 76, 60, 1), 0.2),
);

// 3. Neutral Colors
$neutral-colors: (
        'black': rgba(0, 0, 0, 1),
        'white': rgba(255, 255, 255, 1),
        'gray-100': rgba(248, 249, 250, 1),
        'gray-200': rgba(238, 240, 242, 1),
        'gray-300': rgba(222, 226, 230, 1),
        'gray-400': rgba(208, 210, 209, 1),
        'gray-500': rgba(173, 181, 189, 1),
        'gray-600': rgba(101, 103, 107, 1),
        'gray-700': rgba(73, 80, 87, 1),
        'gray-800': rgba(52, 58, 64, 1),
        'gray-900': rgba(33, 37, 41, 1),
        'text-primary': rgba(48, 50, 56, 1)
);
// 4. Status Colors
$status-colors: (
        'success': map-get($colors, 'secondary'),
        'error': rgba(220, 53, 69, 1),
        'warning': rgba(255, 193, 7, 1),
        'info': rgba(23, 162, 184, 1)
);

// 5. Generate Utility Classes
@each $name, $color in $colors {
  .text-#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background-color: $color;
  }
  .border-#{$name} {
    border-color: $color;
  }
}

@each $name, $color in $neutral-colors {
  .text-#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background-color: $color;
  }
  .border-#{$name} {
    border-color: $color;
  }
}

@each $name, $color in $status-colors {
  .text-#{$name} {
    color: $color;
  }
  .bg-#{$name} {
    background-color: $color;
  }
  .border-#{$name} {
    border-color: $color;
  }
}
