.UploadBackgroundImage {
    h4 {
        font-size: 16px;
        font-weight: 600;
    }
    > p {
        &.alert-warning {
            color: #999999;
            position: relative;
            padding-left: 35px;
            svg {
                position: absolute;
                left: 0;
                top: 6px;
            }
        }
    }
    .GroupButtonType {
        button {
            border-color: #D8DBDE;
            padding: 6px 16px;
            line-height: 20px;
            color: #303238;
            font-size: 14px;
            &.active, &:hover {
                background-color: transparent;
                border-color: #007BFF;
                color: #007BFF;
            }
        }
    }
    .listBackgroundImages {
        display: flex;
        flex-wrap: wrap;
        .itemUploadBackgroundImage {
            width: 80px;
            height: 80px;
            border: 2px solid hsl(0,0%,80%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            margin-right: 8px;
            margin-bottom: 8px;
            text-align: center;
            background-color: #ffffff;
            font-size: 13px;
            line-height: 16px;
            cursor: pointer;
            &.active {
                border-color: #007bff;
            }
            &.selectImage {
                border: 2px dashed #007bff;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
            }

        }
    }
}