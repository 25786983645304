@import 'bootstrap/scss/bootstrap';
@import './mockup-generator/index';
@import './components/index';
@import './utils.scss';
@import './generic';

body {
  font-family: $font-primary;

  ul {
    padding: 0;
    list-style: none;

    li {
      list-style: none;
    }
  }

  .form-control {
    border: 1px solid #d3dcd7;
  }

  .LoadingWrapper {
    .spinner-border {
      position: fixed;
      top: 50%;
      left: 50%;
    }
  }

  .table {
    thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
    }

    .thead-light th {
      color: #495057;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }

    tbody td {
      vertical-align: middle;
    }

    .table-striped > tbody > tr:nth-child(odd) > td,
    .table-striped > tbody > tr:nth-child(odd) > th {
      background-color: #f9fafb;
    }
  }
}

.ProductFacebookPixel {
  .Wrapper {
    background-color: #f8f9fa;
    padding: 16px;
    border-radius: 4px;
  }

  .Label {
    font-weight: 500;
    margin-bottom: 8px;
  }

  ul.Pixels {
    list-style: initial;
    padding-left: 40px;
  }

  li.Pixel {
    list-style: initial;
    overflow-wrap: anywhere;
  }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
        .invalid-feedback
    ) {
  margin-left: unset;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;

  &:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.FeatureImage {
  .ImageWrapper {
    position: relative;
    padding: 0 0 100% 0;
    background-repeat: no-repeat;
    background-position: center;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      overflow: hidden;
      opacity: 0;
    }
  }
}

#PFToast {
  > div[class^='Toast_'] {
    position: fixed;
    z-index: 5500;

    > div:not(:last-child) {
      margin-bottom: 8px;
    }

    .close {
      margin-left: 30px;
    }
  }

  .Toast_top {
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;

    .toast {
      width: 100%;
    }

    > .Wrapper {
      animation-name: slideInDown;
      animation-fill-mode: both;
      animation-duration: 0.5s;
    }
  }

  .Toast_bottomLeft {
    bottom: 20px;
    left: 20px;
  }

  .Icon {
    font-size: 20px;
    margin-right: 8px;
  }
}

.btn {
  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }
}

.InputSearch {
  position: relative;

  > input[type='text'] {
    padding-left: 2.375rem;
  }

  &::before {
    content: '\f002';
    font-family: 'font awesome 5 free';
    font-weight: 900;
    position: absolute;
    left: 0;
    z-index: 4;
    top: 50%;
    transform: translateY(-50%);
    width: 2.375rem;
    text-align: center;
    color: #aaa;
  }
}

.draggable-item {
  list-style: none !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fw-500 {
  font-weight: 500 !important;
}

.invalid-field {
  border: 1px solid #dd3d4c !important;
}

.error-message {
  color: #dc3545;
  font-size: 80%;
}

.table-col-10 {
  width: 10%;
}

.table-col-15 {
  width: 15%;
}

.table-col-20 {
  width: 20%;
}

.table-col-25 {
  width: 25%;
}

.table-col-30 {
  width: 30%;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}
