.TemplateHelper {
  min-width: 450px;

  & * {
    font-size: map-get($font-sizes, 'base');
  }

  &__Item > div:first-child {
    border-width: 1.5px;
  }
}
