@import "../utils";
@import "../generic/colors";

.custom-popover {
  position: absolute;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  z-index: 1000;
  transition: opacity 0.2s ease-in-out;
  width: auto;
  height: auto;
  text-align: center;
}

/* Arrow Styling */
.custom-arrow {
  position: absolute;
  width: 0;
  height: 0;
  //border-style: solid;
}

.custom-arrow::before,
.custom-arrow::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px;
}

/* Top Arrow */
.custom-arrow-top::before {
  border-color: var(--border-color, map-get($neutral-colors, #fff)) transparent transparent transparent;
  bottom: -12px;
}

.custom-arrow-top::after {
  border-color: white transparent transparent transparent;
  bottom: -10px;
}

/* Bottom Arrow */
.custom-arrow-bottom::before {
  border-color: transparent transparent var(--border-color, map-get($neutral-colors, #fff)) transparent;
  top: -12px;
}

.custom-arrow-bottom::after {
  border-color: transparent transparent white transparent;
  top: -10px;
}

/* Left Arrow */
.custom-arrow-left::before {
  border-color: transparent var(--border-color, map-get($neutral-colors, #fff)) transparent transparent;
  right: -12px;
}

.custom-arrow-left::after {
  border-color: transparent white transparent transparent;
  right: -10px;
}

/* Right Arrow */
.custom-arrow-right::before {
  border-color: transparent transparent transparent var(--border-color, map-get($neutral-colors, #fff));
  left: -12px;
}

.custom-arrow-right::after {
  border-color: transparent transparent transparent white;
  left: -10px;
}

.popover-content {
  position: absolute;
  pointer-events: none; // Ensures the popover remains interactive
  padding: 10px;
}

.popover-container {
  position: relative;
}

.popover-container:hover .popover-content {
  display: block;
}