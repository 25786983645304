// Typography Design System

// 1. Font Variables
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Inter', serif;

// 2. Font Size Base
$font-size-base: 14px;

// 3. Font Size Function
@function calculate-font-size($multiplier) {
  @return $font-size-base * $multiplier;
}

// 4. Font Sizes
$font-sizes: (
        'xs': calculate-font-size(0.85),
        'sm': calculate-font-size(1),
        'base': $font-size-base,
        'lg': calculate-font-size(1.3),
        'xl': calculate-font-size(1.5),
        '2xl': calculate-font-size(1.75),
        '3xl': calculate-font-size(2.15),
        '4xl': calculate-font-size(2.6),
        '5xl': calculate-font-size(3.4),
        '6xl': calculate-font-size(4.3)
);

// 5. Font Weights
$font-weights: (
        'light': 300,
        'regular': 400,
        'medium': 500,
        'semibold': 600,
        'bold': 700,
        'extrabold': 800
);

// 6. Line Heights
$line-heights: (
        'tight': 1.2,
        'normal': 1.5,
        'relaxed': 1.75
);

// 7. Mixin for Typography
@mixin typography($size, $weight: 'regular', $lh: 'normal', $family: $font-primary) {
  font-size: map-get($font-sizes, $size);
  font-weight: map-get($font-weights, $weight);
  line-height: map-get($line-heights, $lh);
  font-family: $family;
}

// 8. Utility Classes
.text-xs {
  @include typography('xs');
}

.text-sm {
  @include typography('sm');
}

.text-base {
  @include typography('base');
}

.text-lg {
  @include typography('lg');
}

.text-xl {
  @include typography('xl');
}

.text-2xl {
  @include typography('2xl');
}

.text-3xl {
  @include typography('3xl');
}

.text-4xl {
  @include typography('4xl');
}

.text-5xl {
  @include typography('5xl');
}

.text-6xl {
  @include typography('6xl');
}

.font-light {
  font-weight: map-get($font-weights, 'light');
}

.font-regular {
  font-weight: map-get($font-weights, 'regular');
}

.font-medium {
  font-weight: map-get($font-weights, 'medium');
}

.font-semibold {
  font-weight: map-get($font-weights, 'semibold');
}

.font-bold {
  font-weight: map-get($font-weights, 'bold');
}

.font-extrabold {
  font-weight: map-get($font-weights, 'extrabold');
}

.leading-tight {
  line-height: map-get($line-heights, 'tight');
}

.leading-normal {
  line-height: map-get($line-heights, 'normal');
}

.leading-relaxed {
  line-height: map-get($line-heights, 'relaxed');
}

// 9. Font Families
.font-primary {
  font-family: $font-primary;
}

.font-secondary {
  font-family: $font-secondary;
}

